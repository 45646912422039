import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

const cacheLtr = createCache({
  key: "muiltr",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [rtlPlugin],
});
const ltrTheme = createTheme({ direction: "ltr" });
const rtlTheme = createTheme({ direction: "rtl" });
export default function RtlConversion({ children, rtl = false }) {
  return (
    <>
      {rtl ? (
        <CacheProvider value={cacheRtl}>{children}</CacheProvider>
      ) : (
        <CacheProvider value={cacheLtr}>{children}</CacheProvider>
      )}
    </>
  );
}
