import React, { useEffect, useState } from "react";

//images
import { Image } from "react-image-and-background-image-fade";

//slick slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//components
import JoinUs from "../components/JoinUs";
import FindUs from "../components/FindUs";
import AboutUs from "../components/AboutUs";
import RtlConversion from "../components/RtlConversion";
import { useRouter } from "next/router";
//redux
import { useSelector } from "react-redux";
import useTranslation from "../hooks/useTranslation";
import { Fade } from "react-awesome-reveal";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";

// import required modules
import { Pagination, EffectFade, Autoplay } from "swiper";
import Link from "next/link";
import { useRef } from "react";
import { getAllUsers } from "../graphql/services";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const HomeLayout = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const [counterOn, setCounterOn] = useState(false);

  const USER = useSelector((state) => state.auth.user);
  const userIsAuth = useSelector((state) => state.auth?.isAuth);

  const [clients, setClients] = useState(null);
  const getClients = async () => {
    const { data, error } = await getAllUsers({
      options: {
        where: {
          role: "Client",
          active: true,
          accountStatusArray: "VERIFIED",
        },
      },
    });
    if (error) {
      console.log("Error", error);
    } else {
      setClients(data?.paginatedUsers);
    }
  };

  const [freelancers, setFreelancers] = useState(null);
  const getFreelancers = async () => {
    const { data, error } = await getAllUsers({
      options: {
        where: {
          role: "Freelancer",
          active: true,
          accountStatusArray: "VERIFIED",
        },
      },
    });
    if (error) {
      console.log("Error", error);
    } else {
      setFreelancers(data?.paginatedUsers);
    }
  };

  useEffect(() => {
    getClients();
    getFreelancers();
  }, []);

  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    // fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: true,
  };

  return (
    <>
      <div className="lg:h-screen lg:max-w-[1366px] lg:mx-auto fidarri-home md:px-8">
        {router.locale === "ar" ? (
          <div className="flex sm:flex-row-reverse flex-col-reverse  lg:items-center lg:justify-between lg:gap-20 h-full">
            <div className="arSide pt-[108px] lg:w-1/2 lg:block hidden lg:h-full h-[480px] z-[0]">
              <Slider ref={sliderRef} {...settings}>
                <div key={1}>
                  <LazyLoadImage
                    // src="/images/home-1-fr.jpg"
                    src="/images/home-1-Ar.png"
                    width="100%"
                    height="100%"
                    className="object-cover h-full"
                    effect="blur"
                    // placeholder="blur"
                    // blurDataURL={"/images/home-1-fr.jpg"}
                  />
                </div>
                <div key={2}>
                  <LazyLoadImage
                    // src="/images/home2.jpg"
                    // src="/images/home-3-fr.jpg"
                    src="/images/home-3-Ar.png"
                    width="100%"
                    height="100%"
                    className="object-cover h-full"
                    effect="blur"
                    // placeholder="blur"
                    // blurDataURL={"/images/home-3-fr.jpg"}
                  />
                </div>
              </Slider>
            </div>
            <div className="sm:w-1/2 lg:px-0 px-8 lg:pt-0 pt-10 sm:absolute right-[0%]  lg:right-[10%] -z-[1]">
              <div className="flex flex-col lg:items-start items-center gap-6 lg:gap-9  lg:mt-20 ">
                <div
                  className={`lg:leading-[66px] leading-[44px] flex flex-col lg:items-start items-center text-[#2F2F2F] font-bold font-myriad-bold lg:text-[53px] text-[33px] ${
                    router.locale !== "ar" && "tracking-[0.1em]"
                  } `}
                >
                  <span>{t("Engagez une")}</span>
                  <span className="text-secondary font-Sofia lg:text-[70px] text-[40px]">
                    {t("travailleuse")}
                  </span>
                  <span>{t("à tout moment")}</span>
                </div>
                <p className="text-base text-dark leading-5 font-semibold lg:text-justify text-center tracking-[0.05em] max-w-[440px]">
                  {t(
                    "Que vous soyez un nettoyeur professionnel à la recherche de travail ou quelqu'un ayant besoin de services de nettoyage, nous avons tout ce qu'il vous faut."
                  )}
                </p>
                <div className="flex lg:justify-start lg:items-start justify-center items-center">
                  <Link
                    href={"/feed"}
                    className="bg-secondary text-white py-[12.5px] px-[50px] capitalize rounded-lg w-max ha:hover:bg-primary"
                  >
                    {t("Explorer")}
                  </Link>
                </div>
              </div>
            </div>

            <div className="lg:hidden block sm:w-1/2 w-full">
              <Slider ref={sliderRef} {...settings}>
                <div key={1}>
                  <LazyLoadImage
                    src="/images/home-1-Ar.png"
                    width="100%"
                    height="100%"
                    className="object-cover h-full"
                    effect="blur"
                    // placeholder="blur"
                    // blurDataURL={"/images/home-1-fr.jpg"}
                  />
                </div>
                <div key={2}>
                  <LazyLoadImage
                    src="/images/home-3-Ar.png"
                    width="100%"
                    height="100%"
                    className="object-cover h-full"
                    effect="blur"
                    // placeholder="blur"
                    // blurDataURL={"/images/home-3-fr.jpg"}
                  />
                </div>
              </Slider>
              {/* <Image
                src="/images/home-1-Ar.png"
                width="100%"
                height="100%"
                className="object-cover h-full"
              /> */}
            </div>
          </div>
        ) : (
          <div className="flex sm:flex-row flex-col-reverse lg:items-center lg:justify-between lg:gap-20 h-full">
            <div className="lg:w-1/2 lg:px-0 px-8 lg:pt-0 pt-10">
              <div className="flex flex-col lg:items-start items-center gap-6 lg:gap-9 lg:mt-20">
                <div className="lg:leading-[66px] leading-[44px] flex flex-col lg:items-start items-center text-[#2F2F2F] font-bold font-myriad-bold lg:text-[53px] text-[33px] tracking-[0.1em]">
                  <span>{t("Engagez une")}</span>
                  <span className="text-secondary font-Sofia lg:text-[70px] text-[40px]">
                    {t("travailleuse")}
                  </span>
                  <span>{t("à tout moment")}</span>
                </div>
                <p className="text-base text-dark leading-5 font-semibold lg:text-justify text-center tracking-[0.05em] max-w-[440px]">
                  {t(
                    "Que vous soyez un nettoyeur professionnel à la recherche de travail ou quelqu'un ayant besoin de services de nettoyage, nous avons tout ce qu'il vous faut."
                  )}
                </p>
                <div className="flex lg:justify-start lg:items-start justify-center items-center">
                  <Link
                    href={"/feed"}
                    className="bg-secondary text-white py-[12.5px] px-[50px] capitalize rounded-lg w-max ha:hover:bg-primary"
                  >
                    {t("Explorer")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="pt-[108px] lg:w-1/2 lg:block hidden lg:h-full h-[480px] -z-[0]">
              <Slider ref={sliderRef} {...settings}>
                <div key={1}>
                  <LazyLoadImage
                    src="/images/home-1-fr.jpg"
                    width="100%"
                    height="100%"
                    className="object-cover h-full"
                    effect="blur"
                    // placeholderSrc="/images/home-1-fr.jpg"
                    // placeholder="blur"
                    // blurDataURL={"/images/home-1-fr.jpg"}
                  />
                </div>
                <div key={2}>
                  <LazyLoadImage
                    // src="/images/home2.jpg"
                    src="/images/home-3-fr.jpg"
                    width="100%"
                    height="100%"
                    className="object-cover h-full"
                    effect="blur"
                    // placeholderSrc="/images/home-3-fr.jpg"
                    // placeholder="blur"
                    // blurDataURL={"/images/home-3-fr.jpg"}
                  />
                </div>
              </Slider>
            </div>
            <div className="lg:hidden block">
              <Slider ref={sliderRef} {...settings}>
                <div key={1}>
                  <LazyLoadImage
                    src="/images/home-1-fr.jpg"
                    width="100%"
                    height="100%"
                    className="object-cover h-full"
                    effect="blur"
                    // placeholderSrc="/images/home-1-fr.jpg"
                    // placeholder="blur"
                    // blurDataURL={"/images/home-1-fr.jpg"}
                  />
                </div>
                <div key={2}>
                  <LazyLoadImage
                    // src="/images/home2.jpg"
                    src="/images/home-3-fr.jpg"
                    width="100%"
                    height="100%"
                    className="object-cover h-full"
                    effect="blur"
                    // placeholderSrc="/images/home-3-fr.jpg"
                    // placeholder="blur"
                    // blurDataURL={"/images/home-3-fr.jpg"}
                  />
                </div>
              </Slider>
              {/* <Image
                src="/images/home-1-fr.jpg"
                width="100%"
                height="100%"
                className="object-cover h-full"
              /> */}
            </div>
          </div>
        )}
      </div>
      {/* <button onClick={() => methodDoesNotExist()}>Break the world</button> */}
      <div className="flex lg:flex-col flex-col md:px-8 z-[10]">
        <div className="max-w-[1366px] mx-auto">
          <div className="lg:px-20 px-5 lg:pb-0 pt-20">
            <div className="lg:bg-white lg:-translate-y-28 lg:py-[73px] lg:px-[84px] lg:rounded-[30px] text-dark z-50 lg:shadow-[1px_-1px_40px_15px_rgba(142,58,180,0.15)]">
              <div className="lg:bg-white flex lg:flex-row flex-col items-center justify-between lg:gap-24 gap-5">
                <div className="flex items-center py-[30px] px-[40px] bg-white rounded-[30px] shadow-[1px_-1px_40px_15px_rgba(142,58,180,0.15)] lg:py-0 lg:px-0 lg:bg-transparent lg:rounded-0 lg:shadow-none lg:w-auto w-[100%]">
                  <img
                    src="/svg/search-big.svg"
                    alt="trouver une travailleuse à proximité"
                    className="lg:px-5 px-2"
                  />

                  <div
                    className={`flex flex-col gap-[10px] lg:max-w-[220px] px-5 ${
                      router.locale === "ar" ? "border-r" : "border-l"
                    }  border-[#9D9C9C]`}
                  >
                    <span className="font-semibold lg:text-lg text-base text-dark tracking-[0.05em]">
                      {t("Rechercher")}
                    </span>
                    <p className="lg:text-base text-sm text-dark tracking-[0.05em]">
                      {t("trouver une travailleuse à proximité")}
                    </p>
                  </div>
                </div>
                <div className="flex items-center py-[30px] px-[40px] bg-white rounded-[30px] shadow-[1px_-1px_40px_15px_rgba(142,58,180,0.15)] lg:py-0 lg:px-0 lg:bg-transparent lg:rounded-0 lg:shadow-none lg:w-auto w-[100%]">
                  <img
                    src="/svg/reserve.svg"
                    alt="Embauchez travailleuse que vous choisissez"
                    className="lg:px-5 px-2 "
                  />

                  <div
                    className={`flex flex-col gap-[10px] lg:max-w-[220px] px-5 ${
                      router.locale === "ar" ? "border-r" : "border-l"
                    }  border-[#9D9C9C]`}
                  >
                    <span className="font-semibold lg:text-lg text-base text-dark tracking-[0.05em]">
                      {t("Réserver")}
                    </span>
                    <p className="lg:text-base text-sm text-dark tracking-[0.05em]">
                      {t("Embauchez travailleuse que vous choisissez")}
                    </p>
                  </div>
                </div>
                <div className="flex items-center py-[30px] px-[40px] bg-white rounded-[30px] shadow-[1px_-1px_40px_15px_rgba(142,58,180,0.15)] lg:py-0 lg:px-0 lg:bg-transparent lg:rounded-0 lg:shadow-none lg:w-auto w-[100%]">
                  <img
                    src="/svg/happy.svg"
                    alt="Profitez du service à tout moment"
                    className="lg:px-5 px-2 "
                  />

                  <div
                    className={`flex flex-col gap-[10px] lg:max-w-[220px] px-5 ${
                      router.locale === "ar" ? "border-r" : "border-l"
                    }  border-[#9D9C9C]`}
                  >
                    <span className="font-semibold lg:text-lg text-base text-dark tracking-[0.05em]">
                      {t("heureuse")}
                    </span>
                    <p className="lg:text-base text-sm text-dark tracking-[0.05em]">
                      {t("Profitez du service à tout moment")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:pb-[100px] pb-10 lg:px-0 px-8">
          <div className="max-w-[1366px] mx-auto">
            <div className="flex lg:flex-row flex-col-reverse lg:justify-center lg:gap-[185px] gap-8 items-center">
              <div className="flex flex-col gap-6 lg:px-0 px-8">
                <div className="lg:h-[700px] ">
                  <Fade>
                    <Image
                      src="/images/client.jpg"
                      alt="client"
                      width="100%"
                      height="100%"
                      className="object-contain"
                    />
                  </Fade>
                </div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  <div className="lg:hidden flex lg:justify-start justify-center items-center h-max gap-4 ">
                    <div className="flex flex-col text-dark border-r border-[#8F8F8F] lg:pr-[42px] pr-[20px]">
                      <h2 className="lg:text-[47px] text-[32px] font-bold uppercase tracking-[0.1em]">
                        {counterOn ? (
                          <>
                            +
                            <CountUp
                              start={0}
                              end={freelancers?.totalCount}
                              duration={3}
                              delay={0}
                            />
                          </>
                        ) : (
                          "+"
                        )}
                      </h2>
                      <span className="text-[#8F8F8F]">
                        {t("Travailleuses")}
                      </span>
                    </div>
                    <div className="flex flex-col text-dark lg:pl-[42px] pl-[20px]">
                      <h2 className="lg:text-[47px] text-[32px] font-bold uppercase tracking-[0.1em]">
                        {counterOn ? (
                          <>
                            +
                            <CountUp
                              start={0}
                              end={clients?.totalCount}
                              duration={3}
                              delay={0}
                            />
                          </>
                        ) : (
                          "+"
                        )}
                      </h2>
                      <span className="text-[#8F8F8F]">
                        {t("clients satisfaits")}
                      </span>
                    </div>
                  </div>
                </ScrollTrigger>
              </div>
              <div className="flex flex-col lg:max-w-[600px] lg:pt-10 pt-20">
                <div className="flex flex-col lg:items-start items-center">
                  <span
                    className={`uppercase font-bold flex justify-center items-center lg:text-[42px] text-[24px] lg:leading-[62px] leading-[30px] text-dark ${
                      router.locale !== "ar" && "tracking-[0.1em]"
                    } `}
                  >
                    {t("nous nous soucions de")}
                  </span>
                  <div className="relative w-max -translate-x-4">
                    <Fade>
                      <img
                        src="/svg/text-bg.svg"
                        alt="svg"
                        className="lg:block hidden"
                      />
                    </Fade>
                    <Fade>
                      <img
                        src="/svg/text-bg-mobile.svg"
                        alt="svg"
                        className="lg:hidden block"
                      />
                    </Fade>
                    <span
                      className={`uppercase font-bold absolute left-0 lg:pt-1 right-0 top-0 bottom-0 z-10 text-center lg:text-[42px] text-[24px] leading-[62px] text-dark ${
                        router.locale !== "ar" && "tracking-[0.1em]"
                      } 
                    
                    `}
                    >
                      {t("nos clients")}
                    </span>
                  </div>
                </div>
                <p className="text-justify font-semibold text-base leading-5 text-dark tracking-[0.05em] lg:pt-16 pt-10 pb-0 lg:pb-32">
                  {t(
                    "Chez Feddari, nous accordons une grande importance à nos clients. Votre satisfaction est notre priorité absolue, et nous mettons tout en œuvre pour vous offrir une expérience exceptionnelle ."
                  )}
                  <br />
                  {t(
                    "Nous comprenons que chaque client est unique, avec des besoins et des attentes spécifiques. C'est pourquoi nous nous engageons à vous fournir un service personnalisé qui répond précisément à vos exigences. Nous écoutons attentivement vos demandes, vos préférences et vos commentaires, et nous nous efforçons de les intégrer dans nos prestations de nettoyage."
                  )}
                </p>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  <div className="lg:flex hidden lg:justify-start justify-center items-center h-max gap-10">
                    <div className="flex flex-col text-dark border-r border-[#8F8F8F] lg:pr-[42px] pr-[20px]">
                      <h2 className="text-[47px] font-bold uppercase tracking-[0.1em]">
                        {counterOn ? (
                          <>
                            +
                            <CountUp
                              start={0}
                              end={freelancers?.totalCount}
                              duration={3}
                              delay={0}
                            />
                          </>
                        ) : (
                          "+"
                        )}
                      </h2>
                      <span className="text-[#8F8F8F]">
                        {t("Travailleuses")}
                      </span>
                    </div>
                    <div className="flex flex-col text-dark lg:pl-[42px] pl-[20px]">
                      <h2 className="text-[47px] font-bold uppercase tracking-[0.1em]">
                        {counterOn ? (
                          <>
                            +
                            <CountUp
                              start={0}
                              end={clients?.totalCount}
                              duration={3}
                              delay={0}
                            />
                          </>
                        ) : (
                          "+"
                        )}
                      </h2>
                      <span className="text-[#8F8F8F]">
                        {t("clients satisfaits")}
                      </span>
                    </div>
                  </div>
                </ScrollTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(!userIsAuth || (userIsAuth && USER.role !== "Freelancer")) && (
        <JoinUs />
      )}
      <AboutUs />
      <FindUs />
    </>
  );
};

export default HomeLayout;
