import React from "react";
import Head from "next/head";
//toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//hooks
import useTranslation from "../hooks/useTranslation";

const Layout = (props) => {
  const { t } = useTranslation();
  // console.log("props :", props);
  return (
    <>
      <Head>
        <title>
          {props?.title ? t(props?.title) + " - FiDarri" : "FiDarri"}
        </title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="" />
        <meta
          name="keywords"
          content="fidarri,travaille,job,work,femme,fi,dar,darri,dz"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Sofia&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/favico.ico" />
      </Head>
      {props.children}
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default Layout;
