import React from "react";

//hoc
import Layout from "../hoc/Layout";

//layouts
import HomeLayout from "../layouts/HomeLayout";

//components
import Header from "../components/Header";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <Layout>
      <main className="home-bg-img">
        <Header active="home" />
        <HomeLayout />
        <Footer />
      </main>
    </Layout>
  );
};

export default Home;
